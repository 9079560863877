@import '../lib/root.scss';

#splitter {
  position: absolute;
  right: 0;
  z-index: 1;
  transition: border-radius var(--transition-duration);
  cursor: default;
  background: var(--tabs-bg) var(--bg-gradient);
  padding: 0 10px;
  height: 24pt;
  color: var(--tabs-text);
  direction: rtl; // Move border to the left
  user-select: none;
  text-align: center;
  #inspector:not(.open) & {
    border-top-left-radius: var(--rad_lg);
    border-bottom-left-radius: var(--rad_lg);
  }

  @include tight-screen {
    display: none;
  }
}
