.toggle {
  & > div {
    display: inline-block;
    margin-right: 0.5em;
    border-radius: 1em;
    background-color: var(--bg2);
    width: 4em;
  }
  & > div > div {
    transition: 0.15s;
    margin-left: 0;
    border-radius: 1em;
    background-color: var(--bg1);
    width: 3em;
    text-align: center;
  }
  &.checked > div > div {
    margin-left: 1em;
    background-color: var(--bg-green);
  }
}
