#pane-graph {
  .collapsed {
    opacity: 0.5;
  }
}

#colorize-ui {
  margin-top: 1em;
}

#colorize-ui select {
  margin-left: 0.5em;
}

#colorize-key {
  margin-top: 1rem;
}

// Hide empty sections
.report-section hr:nth-last-child(2),
.report-section h3:nth-last-child(1) {
  display: none;
}
