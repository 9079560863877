#search-field {
  display: block;
  box-sizing: border-box;
  outline: none;
  border: solid 1px var(--text-dim);
  border-radius: 1em;
  padding: 0.3em 0.7em;
  width: 100%;
  font: inherit;
  font-size: 16px; // It must be at least 16px to avoid iOS zooming in on focus

  &:focus {
    border-color: var(--accent);
  }
}

.tip {
  border-radius: 0.5em;
  padding: 0.5em;
  color: var(--text-dim);
  font-style: italic;
  font-size: 0.85em;
  line-height: 1.5em;
}
