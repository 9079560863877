@import 'lib/root.scss';

#graph {
  position: relative;
  flex-grow: 1;
  overflow: auto;
  user-select: none;
  -webkit-user-select: none;

  --warning0: #f6f6e0;
  --warning1: #d9d9d9;
  --stub: #f00;
  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.graphviz-loading,
  &.graphviz-failed {
    margin-top: 20vh;
    text-align: center;
  }
  &.graphviz-failed {
    color: var(--stroke-red);
  }

  @include tight-screen {
    min-height: 80vh;
  }
}

@media (prefers-color-scheme: dark) {
  #graph {
    --warning0: #101010;
    --warning1: #660;
  }
}

#graph-controls {
  display: flex;
  position: fixed;
  gap: 1em;
  inset: auto auto 1em 1em;
  --padding: 6px;

  @include tight-screen {
    inset: 1em 1em auto auto;
  }

  button {
    display: inline-block;
    border: solid 1px var(--bg1);
    background: var(--bg0);
    padding: var(--padding);
    color: var(--text);

    svg {
      display: block;
    }

    &.selected {
      background-color: var(--accent);
      color: var(--text-on-accent);
    }
  }
}
#zoom-buttons {
  display: flex;
}

#zoom-fit-width,
#download-svg {
  border-top-left-radius: var(--rad_sm);
  border-bottom-left-radius: var(--rad_sm);
  padding-left: calc(var(--padding) + 1px);
}

#zoom-fit-height,
#download-svg {
  border-top-right-radius: var(--rad_sm);
  border-bottom-right-radius: var(--rad_sm);
  padding-right: calc(var(--padding) + 1px);
}

#zoom-buttons,
#download-svg {
  box-shadow: 2px 2px 6px var(--bg0-shadow-color);
}

svg#graph-diagram {
  fill: var(--text);
}

pattern#warning {
  .line0 {
    stroke: var(--warning0);
  }
  .line1 {
    stroke: var(--warning1);
  }
}

g .stub {
  opacity: 0.6;

  > path {
    stroke-dasharray: 4, 4;
    stroke: var(--stub);
  }
}

g.node {
  text {
    fill: var(--text);
  }

  path {
    stroke: var(--text);
    fill: var(--bg0);
  }

  &.collapsed {
    opacity: 0.5;
  }

  &.selected {
    path {
      stroke: var(--accent) !important;
      stroke-width: 3;
    }
  }

  &.downstream {
    path {
      stroke: var(--accent);
    }
  }

  &.unselected {
    opacity: 0.35;
  }

  &.warning > path {
    fill: url(#warning);
  }

  * {
    cursor: pointer;
  }
}

g.edge {
  --selected-edge-width: 1;

  > path {
    // stylelint-disable-line no-descending-specificity
    stroke: var(--text);
  }

  > polygon {
    stroke: var(--text);
    fill: var(--text);
  }

  &.downstream {
    & > path {
      stroke: var(--accent);
      stroke-width: var(--selected-edge-width);
    }

    & > polygon {
      stroke: var(--accent);
      fill: var(--accent);
    }
  }

  &.unselected {
    opacity: 0.35;
  }
}

@media print {
  #graph-controls {
    display: none;
  }
}
