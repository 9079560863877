.flash {
  border-radius: var(--rad_sm);
  padding: 4px;
  transition-property: left;
  transition-duration: .7s;
  position: fixed;
}

.icon {
  display: inline-block;
}

.icon-path-stroke {
  stroke: currentColor;
  stroke-width: 1.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.icon-path-fill {
  stroke: none;
  fill: currentColor;
}

.icon-sponsor {
  stroke: var(--bg-violet);
}

a .icon {
  vertical-align: middle;
}

button .icon {
  vertical-align: baseline;
}

.external-link {
  white-space: nowrap;
}

.toggle > div {
  background-color: var(--bg2);
  border-radius: 1em;
  width: 4em;
  margin-right: .5em;
  display: inline-block;
}

.toggle > div > div {
  background-color: var(--bg1);
  text-align: center;
  border-radius: 1em;
  width: 3em;
  margin-left: 0;
  transition: all .15s;
}

.toggle.checked > div > div {
  background-color: var(--bg-green);
  margin-left: 1em;
}

#colorize-ui select {
  border: solid .1rem var(--bg1);
  border-radius: .3rem;
  padding: .3rem;
}

#pane-graph .collapsed {
  opacity: .5;
}

#colorize-ui {
  margin-top: 1em;
}

#colorize-ui select {
  margin-left: .5em;
}

#colorize-key {
  margin-top: 1rem;
}

.report-section hr:nth-last-child(2), .report-section h3:last-child {
  display: none;
}

.root_da1a8f {
  cursor: default;
  border: 1px solid #0000;
}

.root_da1a8f > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.root_da1a8f[open] {
  border-color: var(--bg1);
  border-radius: .3rem;
  margin-bottom: 1rem;
}

.root_da1a8f .zebra-row:nth-child(2n) {
  background-color: #7f7f7f0d;
}

.root_da1a8f summary {
  background-color: var(--bg0);
  color: var(--accent);
  border-radius: .3rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  position: sticky;
  top: 0;
}

.root_da1a8f summary::marker {
  font-size: 0;
}

.symbol_da1a8f {
  vertical-align: middle;
  text-align: left;
  width: 1rem;
  margin-left: .5rem;
  margin-right: .5rem;
  font-size: 1.3rem;
  display: inline-block;
}

.info_da1a8f .symbol_da1a8f {
  color: green;
}

.warn_da1a8f .symbol_da1a8f {
  color: var(--stroke-yellow);
}

.error_da1a8f .symbol_da1a8f {
  color: green;
}

.description_da1a8f {
  color: #7f7f7fd9;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: .9rem;
  line-height: 1.3rem;
}

.Ee7ChW_root {
  --selectable: #840;
  cursor: default;
  color: var(--selectable);
  text-wrap: nowrap;
}

@media (prefers-color-scheme: dark) {
  .Ee7ChW_root {
    --selectable: #fc9;
  }
}

.root_34e502 {
  align-items: flex-start;
  gap: .5rem;
  margin-top: .4rem;
  margin-bottom: .4rem;
  line-height: 1.5rem;
  display: -ms-flexbox;
  display: flex;
}

.license_34e502 {
  -ms-flex: 0 0 17ch;
  flex: 0 0 17ch;
}

.license_34e502 > span {
  text-overflow: ellipsis;
  text-transform: uppercase;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.license_34e502 > img {
  border-radius: .3rem;
  -ms-flex-positive: 0;
  flex-grow: 0;
  width: 32px;
  height: 32px;
}

.license_34e502 > img:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.license_34e502 > img:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.modules_34e502 {
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  font-family: monospace;
  font-size: .9rem;
  display: -ms-flexbox;
  display: flex;
}

.modules_34e502 .selectable {
  text-overflow: ellipsis;
  overflow: hidden;
}

.keywords_34e502 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 0;
  line-height: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.keywords_34e502 .keyword_34e502 {
  white-space: nowrap;
  background-color: #7f7f7f4d;
  border-radius: 1rem;
  margin: .2rem;
  padding: .2rem;
  font-size: .8rem;
}

.bsx1Ba_row {
  line-height: 1.5rem;
  display: -ms-flexbox;
  display: flex;
}

.bsx1Ba_name {
  text-overflow: ellipsis;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.bsx1Ba_entry {
  font-weight: bold;
}

.root_8656d9 {
  align-items: center;
  gap: .5rem;
  margin-top: .4rem;
  margin-bottom: .4rem;
  line-height: 1.5rem;
  display: -ms-flexbox;
  display: flex;
}

.maintainer_8656d9 {
  white-space: nowrap;
  -ms-flex: 0 0 17ch;
  flex: 0 0 17ch;
  align-items: center;
  gap: .3rem;
  min-width: 0;
  display: -ms-flexbox;
  display: flex;
}

.maintainer_8656d9 > span {
  text-overflow: ellipsis;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.maintainer_8656d9 > img {
  border-radius: .3rem;
  -ms-flex-positive: 0;
  flex-grow: 0;
  width: 32px;
  height: 32px;
}

.maintainer_8656d9 > img:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: .5rem;
}

.maintainer_8656d9 > img:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: .5rem;
}

.modules_8656d9 {
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  font-family: monospace;
  font-size: .9rem;
  display: -ms-flexbox;
  display: flex;
}

.modules_8656d9 .selectable {
  text-overflow: ellipsis;
  overflow: hidden;
}

.root_4226a3 {
  margin-bottom: .5rem;
  line-height: 1.5rem;
}

.root_4226a3 .body_4226a3 {
  margin-left: 1rem;
  font-style: italic;
  display: block;
}

.root_4226a3 .selectable:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: 0;
}

.root_4226a3 .selectable:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: 0;
}

.EdYYaW_row {
  gap: 1rem;
  line-height: 1.5rem;
  display: -ms-flexbox;
  display: flex;
}

.EdYYaW_name {
  text-overflow: ellipsis;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.EdYYaW_version {
  -ms-flex-positive: 0;
  flex-grow: 0;
  font-family: monospace;
  font-size: .9rem;
}

.xlPkNq_dim {
  color: var(--text-dim);
}

.xlPkNq_section {
  margin-bottom: 1rem;
}

.xlPkNq_header {
  gap: 0 1rem;
  display: -ms-flexbox;
  display: flex;
}

.xlPkNq_header > :first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow-x: hidden;
}

.xlPkNq_header > :nth-child(2) {
  white-space: nowrap;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.xlPkNq_header_row, .xlPkNq_row {
  gap: .2rem 1rem;
  margin: .3rem 0 .3rem 1rem;
  display: -ms-flexbox;
  display: flex;
}

.xlPkNq_header_row {
  font-weight: bold;
}

.xlPkNq_wants {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.xlPkNq_gets {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

#search-field {
  box-sizing: border-box;
  border: solid 1px var(--text-dim);
  font: inherit;
  border-radius: 1em;
  outline: none;
  width: 100%;
  padding: .3em .7em;
  font-size: 16px;
  display: block;
}

#search-field:focus {
  border-color: var(--accent);
}

.tip {
  color: var(--text-dim);
  border-radius: .5em;
  padding: .5em;
  font-size: .85em;
  font-style: italic;
  line-height: 1.5em;
}

#inspector {
  transition: flex-basis var(--transition-duration);
  border-top-left-radius: var(--rad_lg);
  border-bottom-left-radius: var(--rad_lg);
  color: var(--text);
  -ms-flex-negative: 0;
  -ms-flex-preferred-size: 0;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 0;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

#inspector.open {
  flex-basis: var(--inspector-size);
}

@media (aspect-ratio <= 2 / 3), (width <= 700px) {
  #inspector {
    flex-basis: var(--inspector-size);
    border-radius: var(--rad_lg);
  }
}

#inspector .description {
  font-weight: 600;
}

#inspector .module {
  white-space: nowrap;
}

#inspector .tag {
  cursor: pointer;
  border-radius: var(--rad_lg);
  background-color: var(--bg1);
  color: var(--text);
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 8px;
  overflow: hidden;
}

#inspector .tag.maintainer {
  height: 32px;
  padding: 0;
}

#inspector .tag.maintainer img {
  vertical-align: middle;
  margin-right: 4px;
}

#inspector ul {
  margin-top: 0;
}

#inspector ul:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 1em;
}

#inspector ul:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 1em;
}

label {
  margin: .3em 0;
  display: block;
}

#tabs {
  background: var(--tabs-bg) var(--bg-gradient);
  width: var(--inspector-size);
  color: var(--tabs-text);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  -ms-flex-preferred-size: auto;
  flex-shrink: 0;
  flex-basis: auto;
  display: -ms-flexbox;
  display: flex;
}

#tabs > * {
  line-height: var(--tabs-height);
}

.pane {
  box-sizing: border-box;
  box-shadow: inset 3px 2px 6px var(--bg0-shadow-color);
  background-color: var(--bg0);
  width: var(--inspector-size);
  color: var(--text);
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 1em;
  overflow: hidden auto;
}

.pane a {
  color: var(--accent);
}

.pane hr {
  margin-left: -1em;
  margin-right: -1em;
}

@media (aspect-ratio <= 2 / 3), (width <= 700px) {
  .pane {
    min-height: 200px;
    padding-bottom: 1em;
  }
}

.dist-tag {
  margin-left: 1em;
  display: inline;
}

.G4IVZa_root {
  line-height: 1.7;
}

.G4IVZa_root > summary {
  justify-content: space-between;
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1.17em;
  font-weight: bold;
  line-height: normal;
  display: -ms-flexbox;
  display: flex;
}

.G4IVZa_root summary::-webkit-details-marker {
  display: none;
}

.G4IVZa_root summary:after {
  content: "◀";
}

.G4IVZa_root[open] summary:after {
  content: "▼";
}

._8U32nW_root {
  cursor: default;
  box-sizing: border-box;
  border-radius: var(--rad_sm);
  background-color: var(--bg1);
  text-align: center;
  align-content: center;
  display: grid;
  position: absolute;
  overflow: hidden;
}

._8U32nW_root > span {
  color: var(--bg0);
}

.L6IVJG_label {
  margin-right: 1rem;
}

.L6IVJG_bar {
  border: 1px solid #ccc;
  width: 200px;
}

.L6IVJG_bar > .L6IVJG_inner {
  color: var(--bg0);
  text-align: right;
}

.qG-qXq_stubError {
  color: var(--stroke-orange);
}

#module-version {
  text-align: center;
  border: 1px solid;
  border-radius: .3rem;
  padding: .1rem 0;
  font-size: .85rem;
}

#module-version.dist-tag {
  text-align: inherit;
  border: none;
}

#module-version.major-updates {
  border-color: var(--stroke-red);
}

#module-version.minor-updates {
  border-color: var(--stroke-orange);
}

#module-version.patch-updates {
  border-color: var(--stroke-yellow);
}

.yu0pNG_root {
  border: solid 1px var(--grey70);
  background-color: var(--grey90);
  border-radius: .3rem;
  width: 100%;
  height: 8rem;
}

.yu0pNG_legend {
  justify-content: space-between;
  margin-top: .5rem;
  display: -ms-flexbox;
  display: flex;
}

.yu0pNG_legend .yu0pNG_dotKey {
  vertical-align: middle;
  background-color: red;
  border-radius: 50%;
  width: .7em;
  height: .7em;
  display: inline-block;
}

.yu0pNG_legend .yu0pNG_dotKeyMajor {
  background-color: var(--bg-red);
  width: 1em;
  height: 1em;
}

.yu0pNG_legend .yu0pNG_dotKeyMinor {
  background-color: var(--bg-orange);
}

.yu0pNG_legend .yu0pNG_dotKeyPatch {
  background-color: var(--bg-yellow);
}

.yu0pNG_legend .yu0pNG_dotKeyPrerelease {
  background-color: var(--bg-blue);
}

svg {
  cursor: default;
}

svg > .yu0pNG_layer-grid {
  stroke: var(--grey20);
}

svg .yu0pNG_layer-major {
  fill: var(--bg-red);
}

svg .yu0pNG_layer-minor {
  fill: var(--bg-orange);
}

svg .yu0pNG_layer-patch {
  fill: var(--bg-yellow);
}

svg .yu0pNG_layer-prerelease {
  fill: var(--bg-blue);
}

.yu0pNG_xAxis {
  justify-content: space-between;
  font-size: .9rem;
  display: -ms-flexbox;
  display: flex;
}

#splitter {
  z-index: 1;
  transition: border-radius var(--transition-duration);
  cursor: default;
  background: var(--tabs-bg) var(--bg-gradient);
  color: var(--tabs-text);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  direction: rtl;
  height: 24pt;
  padding: 0 10px;
  position: absolute;
  right: 0;
}

#inspector:not(.open) #splitter {
  border-top-left-radius: var(--rad_lg);
  border-bottom-left-radius: var(--rad_lg);
}

@media (aspect-ratio <= 2 / 3), (width <= 700px) {
  #splitter {
    display: none;
  }
}

.tab {
  font-size: 12pt;
  font-weight: 600;
  position: relative;
}

.tab:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  border-right: solid 1px var(--tabs-border);
}

.tab:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left: solid 1px var(--tabs-border);
}

.tab button {
  all: unset;
  background: var(--tabs-bg) var(--bg-gradient);
  align-items: center;
  padding: 0 10px;
  display: -ms-flexbox;
  display: flex;
}

.tab.active button {
  background: var(--accent);
}

.tab .badge {
  background-color: var(--bg-darkred);
  border-radius: .5rem;
  min-width: .3rem;
  min-height: .3rem;
  padding: .2rem;
  font-size: .7rem;
  font-weight: 600;
  line-height: .7rem;
  display: inline-block;
  position: absolute;
  top: .2rem;
  right: -.1rem;
}

.tab kbd {
  margin-left: .7em;
  font-size: .8rem;
}

._0J_k6W_root:after, ._0J_k6W_root:before {
  z-index: 1000000;
  pointer-events: none;
  animation: .1s _0J_k6W_fade-in;
  position: fixed;
  inset: 0;
}

._0J_k6W_root:before {
  background-color: var(--bg2);
  content: "";
}

._0J_k6W_root:after {
  border: dashed 5px var(--text-dim);
  border-radius: var(--rad_lg);
  content: "Drop to parse dependencies";
  justify-content: center;
  align-items: center;
  font-size: 2em;
  display: -ms-flexbox;
  display: flex;
  inset: 10px;
}

@keyframes _0J_k6W_fade-in {
  from {
    opacity: 0;
  }
}

#graph {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  --warning0: #f6f6e0;
  --warning1: #d9d9d9;
  --stub: red;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

#graph.centered {
  justify-content: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

#graph.graphviz-loading, #graph.graphviz-failed {
  text-align: center;
  margin-top: 20vh;
}

#graph.graphviz-failed {
  color: var(--stroke-red);
}

@media (aspect-ratio <= 2 / 3), (width <= 700px) {
  #graph {
    min-height: 80vh;
  }
}

@media (prefers-color-scheme: dark) {
  #graph {
    --warning0: #101010;
    --warning1: #660;
  }
}

#graph-controls {
  --padding: 6px;
  gap: 1em;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  inset: auto auto 1em 1em;
}

@media (aspect-ratio <= 2 / 3), (width <= 700px) {
  #graph-controls {
    inset: 1em 1em auto auto;
  }
}

#graph-controls button {
  border: solid 1px var(--bg1);
  background: var(--bg0);
  padding: var(--padding);
  color: var(--text);
  display: inline-block;
}

#graph-controls button svg {
  display: block;
}

#graph-controls button.selected {
  background-color: var(--accent);
  color: var(--text-on-accent);
}

#zoom-buttons {
  display: -ms-flexbox;
  display: flex;
}

#zoom-fit-width, #download-svg {
  border-top-left-radius: var(--rad_sm);
  border-bottom-left-radius: var(--rad_sm);
  padding-left: calc(var(--padding)  + 1px);
}

#zoom-fit-height, #download-svg {
  border-top-right-radius: var(--rad_sm);
  border-bottom-right-radius: var(--rad_sm);
  padding-right: calc(var(--padding)  + 1px);
}

#zoom-buttons, #download-svg {
  box-shadow: 2px 2px 6px var(--bg0-shadow-color);
}

svg#graph-diagram {
  fill: var(--text);
}

pattern#warning .line0 {
  stroke: var(--warning0);
}

pattern#warning .line1 {
  stroke: var(--warning1);
}

g .stub {
  opacity: .6;
}

g .stub > path {
  stroke-dasharray: 4 4;
  stroke: var(--stub);
}

g.node text {
  fill: var(--text);
}

g.node path {
  stroke: var(--text);
  fill: var(--bg0);
}

g.node.collapsed {
  opacity: .5;
}

g.node.selected path {
  stroke-width: 3px;
  stroke: var(--accent) !important;
}

g.node.downstream path {
  stroke: var(--accent);
}

g.node.unselected {
  opacity: .35;
}

g.node.warning > path {
  fill: url("#warning");
}

g.node * {
  cursor: pointer;
}

g.edge {
  --selected-edge-width: 1;
}

g.edge > path {
  stroke: var(--text);
}

g.edge > polygon {
  stroke: var(--text);
  fill: var(--text);
}

g.edge.downstream > path {
  stroke: var(--accent);
  stroke-width: var(--selected-edge-width);
}

g.edge.downstream > polygon {
  stroke: var(--accent);
  fill: var(--accent);
}

g.edge.unselected {
  opacity: .35;
}

@media print {
  #graph-controls {
    display: none;
  }
}

.bright-hover {
  will-change: filter;
  transition: filter .2s;
}

.bright-hover:hover {
  filter: brightness(125%);
  transition-duration: .1s;
}

.warning {
  background: repeating-linear-gradient(-45deg, #8080001a, #8080001a 10px, #0000001a 10px 20px);
}

@media print {
  #inspector, #splitter {
    display: none;
  }
}

.RhN1Gq_root {
  box-sizing: border-box;
  background: radial-gradient(var(--bg-root), color-mix(in lab, var(--accent) 10%, var(--bg-root)));
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;
  padding: 1em;
  display: -ms-flexbox;
  display: flex;
}

@media (prefers-color-scheme: dark) {
  .RhN1Gq_root {
    background: radial-gradient(var(--bg0), var(--bg-root));
  }
}

.RhN1Gq_root h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Monaco, monospace;
  font-size: 4em;
}

.RhN1Gq_root h1 svg {
  max-width: 300px;
  max-height: 55px;
}

.RhN1Gq_root h2 {
  text-align: center;
}

.RhN1Gq_root input {
  max-width: 20em;
  margin-top: .8em;
  margin-bottom: .8em;
  font-size: 2em !important;
}

.RhN1Gq_root a {
  color: var(--accent);
}

.RhN1Gq_root ul {
  margin: 0;
}

.P36lFG_root svg {
  fill: var(--accent);
  color: #fff;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.P36lFG_arm {
  transform-origin: 130px 106px;
}

.P36lFG_root:hover .P36lFG_arm {
  animation: .56s ease-in-out P36lFG_octocat-wave;
}

@keyframes P36lFG_octocat-wave {
  0%, 100% {
    transform: rotate(0);
  }

  20%, 60% {
    transform: rotate(-25deg);
  }

  40%, 80% {
    transform: rotate(10deg);
  }
}

@media (width <= 500px) {
  .P36lFG_root:hover .P36lFG_arm {
    animation: none;
  }

  .P36lFG_root .P36lFG_arm {
    animation: .56s ease-in-out P36lFG_octocat-wave;
  }
}

._3AxyYW_root path:nth-child(4) {
  fill: color-mix(in srgb, var(--text), var(--accent) 48%);
}

._3AxyYW_root path:nth-child(5) {
  fill: color-mix(in srgb, var(--text), var(--accent) 60%);
}

._3AxyYW_root path:nth-child(6) {
  fill: color-mix(in srgb, var(--text), var(--accent) 72%);
}

._3AxyYW_root path:nth-child(7) {
  fill: color-mix(in srgb, var(--text), var(--accent) 84%);
}

._3AxyYW_root path:nth-child(8) {
  fill: color-mix(in srgb, var(--text), var(--accent) 96%);
}

.loader {
  z-index: 99;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 5px var(--bg-root);
  background: var(--bg-root);
  max-width: var(--inspector-size);
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-right-radius: 1em;
  padding: .5em;
  font-weight: 600;
  position: fixed;
  left: 0;
  overflow: hidden;
}

@keyframes MOVE-BG {
  from {
    transform: translateX(-56.57px);
  }

  to {
    transform: translateX(0);
  }
}

.progress-bar {
  z-index: 9999;
  background: repeating-linear-gradient(-45deg, white 0, white 20px, var(--accent) 20px, var(--accent) 40px);
  width: calc(100% + 57px);
  height: 2pt;
  animation-name: MOVE-BG;
  animation-duration: 1.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: fixed;
  inset: 0;
}

.S45tZG_root {
  border: 1px solid #000;
  border-radius: 10px;
  max-width: 40rem;
  margin: 2rem auto;
  padding: 1rem;
  position: relative;
}

.S45tZG_root h1 {
  text-align: center;
}

.S45tZG_root code {
  color: var(--stroke-orange);
  border-radius: 3px;
  padding: .1rem .3rem;
}

.S45tZG_footer {
  text-align: right;
}

.S45tZG_sad_kilroy {
  position: absolute;
  bottom: -10px;
  left: 100px;
}
/*# sourceMappingURL=index.635828f8.css.map */
