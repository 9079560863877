.loader {
  position: fixed;
  left: 0;
  z-index: 99;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 5px var(--bg-root);
  border-bottom-right-radius: 1em;
  background: var(--bg-root);
  padding: 0.5em;
  max-width: var(--inspector-size);

  overflow: hidden;
  pointer-events: none;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;

  @keyframes MOVE-BG {
    from {
      transform: translateX(-56.57px);
    } // sqrt(2) * gradient_width
    to {
      transform: translateX(0);
    }
  }
}

.progress-bar {
  position: fixed;
  z-index: 9999;

  animation-duration: 1.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: MOVE-BG;
  inset: 0;
  background: repeating-linear-gradient(
    -45deg,
    white 0,
    white 20px,
    var(--accent) 20px,
    var(--accent) 40px
  );
  width: calc(100% + 57px);
  height: 2pt;
}
